import { type EmailListType as EmailAddressListType } from '@/shared/constants/action'
import { type AllContextDataType } from '@/shared/constants/context'
import { MAX_TASK_MINUTES } from '@/shared/constants/schedule'
import {
  TASK_TODO_STATUSES,
  TIME_SCORE_HOUR,
  type NewOrExistingPartialTaskWithRelations,
  type NewOrExistingTask,
  type TaskWithActions,
  type TaskWithAssignedUser,
  type TaskWithContextItems,
  type TaskWithContextItemsAndActions,
  type TaskWithRelations,
} from '@/shared/constants/task'
import dayjs from '@/shared/singletons/dayjs'
import { getActionsWordCount } from '@/shared/utils/action'
import { parseEmailString } from '@/shared/utils/email'
import {
  getTaskOriginEmail,
  isGmailData,
  isGmailTask,
} from '@/shared/utils/gmail'
import {
  getConversationTypePrefix,
  getConversationUsers,
  getTaskOriginConversation,
  isSlackData,
  isSlackTask,
} from '@/shared/utils/slack'
import { getMillis } from '@/shared/utils/time'
import { Feedback, Score, TaskStatus, type Task } from '@prisma/client'
import { addMinutes, isSameDay, subHours } from 'date-fns'

////////////////
// TIME SAVED //
////////////////

export function getTimeSaved(
  task: TaskWithRelations,
  { useCompletedOnly = true }: { useCompletedOnly?: boolean } = {
    useCompletedOnly: true,
  }
): number {
  const estimatedTime = getMillis({
    readWordCount: task.originalWordCount ?? 0,
    writeWordCount: getActionsWordCount(task.actions, {
      useCompletedOnly,
    }),
  })
  const actualTime = getMillis({
    readWordCount: task.wordCount ?? 0,
    writeWordCount: 0,
  })
  return estimatedTime - actualTime
}

export function getTimeSavedString(task: TaskWithRelations): string {
  const timeSavedString = dayjs
    .duration(getTimeSaved(task), 'milliseconds')
    .humanize()
  return timeSavedString
}

//////////////
// TRACKING //
//////////////

export function getTrackingData(
  task: TaskWithRelations,
  { prefix = '' } = { prefix: '' }
): Record<string, unknown> {
  return {
    [`${prefix}taskTitle`]: task.title,
    [`${prefix}taskDescription`]: task.description,
    [`${prefix}taskType`]: task.type,
    [`${prefix}taskTimeSaved`]: getTimeSavedString(task),
    [`${prefix}taskIntegrationName`]: task.integrationName,
    [`${prefix}taskPriority`]: task.priority,
  }
}

//////////
// DATA //
//////////

export function getEmailAddressList({
  task,
  allContextData,
}: {
  task: TaskWithContextItems
  allContextData: AllContextDataType
}): EmailAddressListType {
  console.log(
    'Getting email addresses from task: %s',
    JSON.stringify(task, null, 2)
  )

  let emailAddressList: EmailAddressListType = []

  if (isSlackTask(task) && isSlackData(allContextData)) {
    console.log('Extracting emails from Slack conversation in task')
    const users = allContextData.conversations.flatMap(conversation =>
      getConversationUsers(conversation)
    )
    emailAddressList = users
      .map(({ name, email }) => ({
        name,
        email,
      }))
      .filter(({ name, email }) => !!name && !!email) as EmailAddressListType
  }

  console.log(
    'Found %d email addresses in task: %O',
    emailAddressList.length,
    JSON.stringify(emailAddressList, null, 2)
  )

  return emailAddressList
}

////////////
// TOKENS //
////////////

export function getTokenCount(task: TaskWithRelations): number {
  return (
    (task.tokenCount ?? 0) +
    (task.actions?.reduce(
      (count, action) => count + (action?.tokenCount ?? 0),
      0
    ) ?? 0)
  )
}

/////////////////
// READ STATUS //
/////////////////

export const isNew = (
  task?: NewOrExistingPartialTaskWithRelations | null
): boolean => !!task && task.lastReadDate == null && !task.completedDate

export const isUpdated = (
  task?: NewOrExistingPartialTaskWithRelations | null
): boolean => {
  if (!task || !task.lastReadDate || !task.updatedAt || !!task.completedDate) {
    return false
  }

  // NOTE: Must be kept in sync with tasks.count
  const millisReadToUpdated =
    new Date(task.updatedAt).getTime() - new Date(task.lastReadDate).getTime()
  const result = millisReadToUpdated > 5000

  return result
}

/////////////////
// INTEGRATION //
/////////////////

export function getTaskIntegrationTitle({
  task,
  allContextData,
}: {
  task: TaskWithContextItems
  allContextData?: AllContextDataType
}): string | null {
  if (isSlackTask(task) && isSlackData(allContextData)) {
    const taskOriginConversation = getTaskOriginConversation(allContextData)
    if (!taskOriginConversation) {
      return null
    }

    const prefix = getConversationTypePrefix(taskOriginConversation.type)
    return prefix + taskOriginConversation.name
  }
  if (isGmailTask(task) && isGmailData(allContextData)) {
    const taskOriginEmail = getTaskOriginEmail(allContextData)
    const { name, email } = parseEmailString(taskOriginEmail?.from)
    return name ?? email ?? null
  }

  // return INTEGRATION_DATA[task.integrationName].displayName
  return null
}

////////////
// STATUS //
////////////

// Status inference
export const isScheduled = (
  task?: NewOrExistingPartialTaskWithRelations | null
): task is Task & ({ scheduledDate: Date } | { dueDate: Date }) =>
  !!task?.dueDate || !!task?.scheduledDate
export const isComplete = (
  task?: NewOrExistingPartialTaskWithRelations | null
): task is Task & { completedDate: Date } => !!task?.completedDate
export const isIncomplete = (
  task?: NewOrExistingPartialTaskWithRelations | null
): boolean => !task?.completedDate
// Same as archived
export const isCancelled = (
  task?: NewOrExistingPartialTaskWithRelations | null
): boolean =>
  task?.feedback === Feedback.BAD ||
  !!(task?.expiryDate && task.expiryDate < new Date()) ||
  task?.status === TaskStatus.CANCELLED
export const isUnread = (
  task?: NewOrExistingPartialTaskWithRelations | null
): boolean => isNew(task) // || isUpdated(task)
export const isTodo = (task?: NewOrExistingTask | null): boolean =>
  TASK_TODO_STATUSES.includes(getStatus(task)) && !isCancelled(task)

export function getStatus(task?: NewOrExistingTask | null): TaskStatus {
  if (isComplete(task)) {
    return TaskStatus.COMPLETED
  }

  if (
    task?.status &&
    (task.status !== TaskStatus.TODO ||
      ('manualFields' in task &&
        task.manualFields &&
        Array.isArray(task.manualFields) &&
        task.manualFields.includes('status')))
  ) {
    return task?.status
  }

  if (isCancelled(task)) {
    return TaskStatus.CANCELLED
  }

  if (isScheduled(task)) {
    return TaskStatus.SCHEDULED
  }

  return TaskStatus.TODO
}

//////////
// TIME //
//////////

export function getTimeScore(task: TaskWithRelations): Task['time'] | null {
  if (task?.hours != null || task?.minutes != null) {
    return calculateTime(task.hours, task.minutes)
  }

  if (
    task?.time ||
    (task && 'manualFields' in task && task?.manualFields?.includes('time'))
  ) {
    return task?.time ?? null
  }

  return null
}

export function getHours(task: TaskWithRelations): number | null {
  const minutes = getMinutes(task)
  if (minutes != null) {
    return minutes / 60
  }

  return null
}

export function getMinutes(task?: TaskWithRelations | null): number | null {
  if (task?.hours != null || task?.minutes != null) {
    return (task.hours ?? 0) * 60 + (task.minutes ?? 0)
  }

  if (task?.time) {
    return TIME_SCORE_HOUR[task.time] * 60
  }

  return null
}

export function getScheduleHours(task: TaskWithRelations): number {
  return getScheduleMinutes(task) / 60
}

export function getScheduleMinutes(task: TaskWithRelations): number {
  return Math.max(Math.min(getMinutes(task) ?? 60, MAX_TASK_MINUTES), 1)
}

export function calculateTime(
  hours?: number | null,
  minutes?: number | null
): Task['time'] | null {
  if (hours == null && minutes == null) {
    return null
  }

  const totalMinutes = (hours ?? 0) * 60 + (minutes ?? 0)

  if (totalMinutes <= TIME_SCORE_HOUR.LOW * 60) {
    return Score.LOW
  } else if (totalMinutes <= TIME_SCORE_HOUR.MEDIUM * 60) {
    return Score.MEDIUM
  }

  return Score.HIGH
}

export function getTimeString(task?: Task | null): string | null {
  const totalMinutes = getMinutes(task)
  if (totalMinutes == null) {
    return null
  }

  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.floor(totalMinutes % 60)
  return `${hours ? `${hours}h` : ''}${minutes || !hours ? `${minutes}m` : ''}`
}

////////////////
// SCHEDULING //
////////////////

export function isScheduledOnDay(task: Task, day: Date): boolean {
  const startTime = getStartTime(task)
  const endTime = getEndTime(task)
  return (
    !!task.scheduledDate &&
    !!startTime &&
    !!endTime &&
    (isSameDay(startTime, day) || isSameDay(endTime, day))
  )
}

export function wasCompletedOnDay(
  task: Task,
  day: Date
): task is Task & { completedDate: Date } {
  return !!task.completedDate && dayjs(task.completedDate).isSame(day, 'day')
}

export function getStartTime(task: Task): Date | null {
  if (isComplete(task)) {
    return subHours(task.completedDate, getScheduleHours(task))
  }
  if (isScheduled(task)) {
    return dayjs(task.scheduledDate ?? task.dueDate).toDate()
  }

  return null
}

export function getEndTime(task: Task): Date | null {
  if (isComplete(task)) {
    return task.completedDate
  }
  if (isScheduled(task)) {
    return addMinutes(
      (task.scheduledDate ?? task.dueDate)!,
      getScheduleMinutes(task)
    )
  }

  return null
}

////////////
// AUTHOR //
////////////

export const isSuggested = (task: Task): boolean => !task.authorId

//////////////
// CHECKERS //
//////////////

export const isTask = (task: unknown): task is Task =>
  typeof task === 'object' && !!task && 'id' in task && 'title' in task

export const isTaskWithAssignedUser = (
  task?: Task | TaskWithAssignedUser | null
): task is TaskWithAssignedUser => !!task && 'assignedUser' in task

export const isTaskWithContextItems = (
  task?: Task | TaskWithContextItems | null
): task is TaskWithContextItems =>
  !!task &&
  'contexts' in task &&
  Array.isArray(task.contexts) &&
  ((task.contexts.length > 0 &&
    !!task.contexts?.[0] &&
    typeof task.contexts?.[0] === 'object' &&
    'items' in task.contexts?.[0]) ||
    task.contexts.length === 0)

export const isTaskWithActions = (
  task?: Task | TaskWithActions | null
): task is TaskWithActions =>
  !!task &&
  'actions' in task &&
  Array.isArray(task.actions) &&
  ((task.actions.length > 0 && !!task.actions?.[0]) ||
    task.actions.length === 0)

export const isTaskWithContextItemsAndActions = (
  task?: Task | TaskWithContextItemsAndActions | null
): task is TaskWithContextItemsAndActions =>
  isTaskWithContextItems(task) && isTaskWithActions(task)

export const isTaskOwnedByTeam = ({
  task,
  teamUserIds,
}: {
  task: Task
  teamUserIds: string[]
}): boolean => {
  const isOwnedByTeam =
    teamUserIds.includes(task.userId) ||
    (!!task.authorId && teamUserIds.includes(task.authorId)) ||
    (!!task.assignedUserId && teamUserIds.includes(task.assignedUserId))

  return isOwnedByTeam
}
