import { type ACTION_TYPES_WITHOUT_INTEGRATION } from '@/shared/constants/action'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { ActionType } from '@prisma/client'

export const ACTION_TYPE_WITHOUT_INTEGRATION_ICON: Partial<
  Record<
    (typeof ACTION_TYPES_WITHOUT_INTEGRATION)[number],
    (props: Partial<FontAwesomeIconProps>) => JSX.Element
  >
> = {
  [ActionType.FOLLOW_LINK]: ({ width, height }) => (
    <FontAwesomeIcon
      width={width}
      height={height}
      icon={faArrowUpRightFromSquare}
    />
  ),
}
