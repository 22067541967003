import { type ActionParametersType } from '@/shared/constants/action'
import { type TaskWithContextItems } from '@/shared/constants/task'
import { getButtonText } from '@/shared/utils/action'
import { Button, type ButtonProps } from '@/web/components/ui/button'
import { ACTION_TYPE_WITHOUT_INTEGRATION_ICON } from '@/web/constants/action'
import { INTEGRATION_DATA } from '@/web/constants/integration'
import { useAllContextData } from '@/web/hooks/use-all-context-data'
import { type ActionType, type IntegrationName } from '@prisma/client'

type TaskActionButtonRawProps<T extends ActionType> = {
  integrationName: IntegrationName | null
  type: T
  parameters: ActionParametersType<T>
  task: TaskWithContextItems
}
type TaskActionButtonProps<T extends ActionType> = Partial<
  Omit<ButtonProps, keyof TaskActionButtonRawProps<T>>
> &
  TaskActionButtonRawProps<T>
export const TaskActionButton = <T extends ActionType>({
  integrationName,
  type,
  parameters,
  task,
  ...buttonProps
}: TaskActionButtonProps<T>) => {
  const Icon = integrationName
    ? INTEGRATION_DATA[integrationName].Icon
    : Object.keys(ACTION_TYPE_WITHOUT_INTEGRATION_ICON).includes(type)
    ? ACTION_TYPE_WITHOUT_INTEGRATION_ICON[
        type as keyof typeof ACTION_TYPE_WITHOUT_INTEGRATION_ICON
      ]
    : null

  const { data: allContextData } = useAllContextData()

  return (
    <Button theme='secondary' size='sm' {...buttonProps}>
      {Icon
        ? Icon({
            isColored: true,
            width: 16,
            height: 16,
          })
        : null}
      {getButtonText({
        integrationName,
        actionType: type,
        parameters: parameters as unknown as ActionParametersType<typeof type>,
        task,
        allContextData,
      })}
    </Button>
  )
}
