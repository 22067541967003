import { type IntegrationContextDataType } from '@/shared/constants/context'
import { SAMPLE_CONTEXT_DATA_1 } from '@/shared/constants/demo'
import { isTaskWithContextItems } from '@/shared/utils/task'
import { DemoContext } from '@/web/contexts/demo'
import { TaskContext } from '@/web/contexts/task'
import { api } from '@/web/utils/api'
import { type IntegrationName } from '@prisma/client'
import { useContext } from 'react'

export function useContextData<TIntegrationName extends IntegrationName>(
  integrationName: TIntegrationName
) {
  const task = useContext(TaskContext)
  const isDemo = useContext(DemoContext)
  const integrationContext = isTaskWithContextItems(task)
    ? task.contexts.find(context => context.integrationName === integrationName)
    : null
  const count = isDemo ? 1 : integrationContext?.items?.length ?? null
  const hasContextItems = !!count

  const loadContextResult = api.context.load.useQuery(
    {
      integrationName: integrationName,
      contextId: integrationContext?.id ?? '',
      taskId: task?.id ?? '',
    },
    {
      enabled: !!task?.id && !isDemo && hasContextItems,
    }
  )

  const data = isDemo ? SAMPLE_CONTEXT_DATA_1 : loadContextResult.data
  const isLoading = loadContextResult.isInitialLoading

  return {
    data: data as IntegrationContextDataType<TIntegrationName>,
    isLoading,
    count,
  }
}
