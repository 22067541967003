export type TaskScheduleType = {
  id: string
  scheduledDate: Date | null
}

export interface ScheduleItemType {
  id: string
  startDate: Date
  endDate: Date
  overlappingItems?: ScheduleItemType[]
}

export type DateRangeType = {
  start: Date
  end: Date
}

export const MILLIS_IN_DAY = 86400000

export const MAX_TASK_MINUTES = 4 * 60
