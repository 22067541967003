import {
  SUPPORTED_TASK_CONTEXT_INTEGRATION_NAMES,
  type AllContextDataType,
  type ContextDataType,
} from '@/shared/constants/context'
import { SAMPLE_CONTEXT_DATA_1 } from '@/shared/constants/demo'
import { DemoContext } from '@/web/contexts/demo'
import { useContextData } from '@/web/hooks/use-context-data'
import _ from 'lodash'
import { useContext } from 'react'

export const useAllContextData = () => {
  const isDemo = useContext(DemoContext)

  const contextDataResults = SUPPORTED_TASK_CONTEXT_INTEGRATION_NAMES.map(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    integrationName => useContextData(integrationName)
  )

  const allContextData = isDemo
    ? SAMPLE_CONTEXT_DATA_1
    : _.reduce(
        contextDataResults,
        (acc, result) => ({
          ...acc,
          ...(result.data as ContextDataType),
        }),
        {} as AllContextDataType
      )

  return {
    data: allContextData as AllContextDataType,
    isLoading: contextDataResults.some(result => result.isLoading),
  }
}
